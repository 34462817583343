import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { DisplaySmall, Heading } from "../components/typography"
import BookSidebar from "../components/bookSidebar"
import Resources from "../components/resources"
import Gut from "../components/gut"
import { Helmet } from "react-helmet"
import InnerSearchForm from "../components/innerSearchForm"
import { Box } from "theme-ui"

const AdditionalResourcesPage = ({ data: { book }, pageContext }) => {
  // console.log(book)
  return (
    <Layout>
      <SEO title={book.title} />
      <Box sx={{ mt: [3, 4, 4] }}>
        <InnerSearchForm />
      </Box>
      <Helmet>
        <meta property="og:image" content={book.cover.url} />
      </Helmet>
      <Wrapper>
        <Container>
          <CustomRow>
            <Col lg={5}>
              <BookSidebar book={book} />
            </Col>
            <Col lg={7}>
              <Gut gutter={2}>
                <Heading>Risorse integrative</Heading>
                <Resources
                  resources={book.additionalResources}
                  links={book.additionalResourcesLinks}
                />
                {book.moreAdditionalResources && (
                  <Heading>...e molto altro nel libro digitale</Heading>
                )}
                <Link to="../">‹ Torna al libro</Link>
              </Gut>
            </Col>
          </CustomRow>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomRow = styled(Row)`
  @media screen and (max-width: 991px) {
    & > * {
      margin-bottom: 4rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export default AdditionalResourcesPage

export const query = graphql`
  query BookAdditionalResourcesQuery($originalId: String!) {
    book: datoCmsBook(originalId: { eq: $originalId }) {
      title
      slug
      originalId
      cover {
        fluid(maxWidth: 400) {
          ...GatsbyDatoCmsFluid
        }
      }
      description
      pageNumber
      isbn
      price
      digitalDownload
      authors {
        name
        id
      }
      bookSheet {
        url
        format
      }
      adoptionRelation {
        url
        format
      }
      video {
        providerUid
      }
      additionalResources {
        url
        title
        format
        originalId
      }
      additionalResourcesLinks {
        anchor
        url
        originalId
      }
      moreAdditionalResources
      teachersResources {
        url
        title
        format
        originalId
      }
      besResources {
        url
        title
        format
        originalId
      }
    }
  }
`
